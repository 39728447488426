<script setup lang="ts">
import type { NovaBoxTopBannerProps } from './NovaBoxTopBanner.types'

const props = defineProps<NovaBoxTopBannerProps>()

const { gtEvent } = useGoogleTag()
const topBannerRef = ref<HTMLElement>()
const layoutStore = useLayoutStore()
const { responsive } = storeToRefs(layoutStore)
const imgUrl = computed(() =>
  responsive.value === 'mobile'
    ? props.source.bannerMobileImageUrl
    : props.source.bannerImageUrl
)

const handleOnGoLink = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '배너 링크 클릭',
    eventLabel: '',
    eventSlot: `${props.gaSlot}`,
    eventI18nAddr: '',
    eventComponent: 'NovaBoxTopBanner',
    ...props.source,
  })
  if (props.source.bannerLinkAt === 'Y')
    useNavigations({
      external: props.source.bannerExtrlLinkAt === 'Y',
      url: `/${props.source.bannerLinkUrl}`,
    })
}
</script>

<template>
  <button
    ref="topBannerRef"
    type="button"
    class="top-banner-item"
    @click="handleOnGoLink"
  >
    <NovaImageContainer
      :key="imgUrl"
      :ratio="'5:2'"
      :image-url="imgUrl"
      :responsive-size="{
        desktop: '5:2',
        mobile: '2:1',
      }"
    />
  </button>
</template>

<style lang="scss" scoped>
.top-banner-item {
  width: 100%;
  height: 100%;
}
</style>
