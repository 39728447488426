<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination, Navigation, Autoplay } from 'swiper/modules'
import type { Swiper as SwiperType } from 'swiper'
import type { NovaLayoutTopBannerProps } from './NovaLayoutTopBanner.types'

const props = withDefaults(defineProps<NovaLayoutTopBannerProps>(), {
  isLoading: false,
  isError: false,
})
const swiper = ref<SwiperType | null>(null)
const currentSlide = ref(1)
const totalSlides = ref(props.banners.length)
const onSwiper = (swiperInstance: SwiperType) => {
  swiper.value = swiperInstance
  totalSlides.value = swiperInstance.slides.length
}
const onSlideChange = () => {
  if (swiper.value) {
    currentSlide.value = swiper.value.realIndex + 1
  }
}
const swiperOptions = computed(() => ({
  pagination: {
    clickable: true,
  },
  watchOverflow: true,
  loop: props.banners.length > 1,
  loopAdditionalSlides: 1,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  modules: props.banners.length > 1 ? [Navigation, Pagination, Autoplay] : [],
}))
onMounted(() => {
  if (swiper.value) {
    totalSlides.value = swiper.value.slides.length
  }
})
</script>
<template>
  <ClientOnly>
    <div class="top-banner-wrap">
      <div class="title-box">
        <p class="title-sub">{{ $t('mainTop.topBanner.titleSub') }}</p>
        <p v-dompurify-html="$t('mainTop.topBanner.title')" class="title"></p>
      </div>
      <div class="top-banner-box">
        <div class="top-banner">
          <Swiper
            v-if="!isLoading && !isError"
            v-bind="swiperOptions"
            class="swiper-top-banner"
            :pagination="{
              clickable: true,
              el: '.swiper-pagination',
              type: 'custom',
            }"
            @swiper="onSwiper"
            @slide-change="onSlideChange"
          >
            <SwiperSlide v-for="banner in banners" :key="banner.bannerSn">
              <NovaBoxTopBanner :source="banner" :ga-slot="gaSlot" />
            </SwiperSlide>
          </Swiper>
          <div class="swiper-pagination">
            {{ currentSlide }} / {{ totalSlides }}
          </div>
          <NovaLoadingIndicator
            v-if="isLoading"
            :fill="true"
            :bg-bright="'light'"
            class="loading-indicator"
          />
          <NovaBoxEmptyMessage
            v-if="isError"
            :message="$t('home.topBannerError')"
          />
        </div>
      </div>
    </div>
  </ClientOnly>
</template>

<style lang="scss" scoped>
.top-banner-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  gap: 80px;

  @include tablet {
    gap: 20px;
  }
  @include mobile {
  }

  .title-box {
    transition: all 0.5s ease;
    @include mobile {
      display: none;
    }

    .title-sub {
      padding-bottom: 16px;
      @include text-style($text-custom-18-regular);
      color: $color-text-12;
      transition: all 0.3s ease;
      @include tablet {
        @include text-style($text-body-14-regular);
      }
    }

    .title {
      @include text-style($text-custom-32-medium);
      font-weight: 700;
      color: #000000;
      transition: all 0.3s ease;
      @include tablet {
        @include text-style($text-custom-22-medium);
        font-weight: 700;
      }
    }
  }

  .top-banner-box {
    width: 100%;
    max-width: 560px;
    @include mobile {
      max-width: none;
      padding: 0 16px;
    }
    .top-banner {
      position: relative;
      width: 100%;
      border-radius: 25px;
      overflow: hidden;
      background-color: $color-bg-3;
      font-size: 0;
      z-index: 0;
      @include mobile {
        border-radius: 18px;
      }
      .swiper-pagination {
        width: 50px;
        bottom: 16px;
        left: auto;
        right: 16px;
        padding: 2px 6px;
        font-size: 14px;
        color: $color-bg-3;
        border-radius: 12px;
        background-color: $color-black-30p;
      }
      .loading-indicator {
        height: 220px;
      }
    }
  }
}
</style>
